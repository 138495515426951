// extracted by mini-css-extract-plugin
export var amountContainer = "cursor_animation_content-module--amountContainer--440ee";
export var bubble = "cursor_animation_content-module--bubble--7b784";
export var bubbleMaxAmt = "cursor_animation_content-module--bubbleMaxAmt--c43af";
export var bubbleTitle = "cursor_animation_content-module--bubbleTitle--1634d";
export var container = "cursor_animation_content-module--container--81d05";
export var currency = "cursor_animation_content-module--currency--f4e45";
export var eligibilityIcon = "cursor_animation_content-module--eligibilityIcon--d1e2a";
export var eligibilityItem = "cursor_animation_content-module--eligibilityItem--facd2";
export var eligibilityListContainer = "cursor_animation_content-module--eligibilityListContainer--5a662";
export var eligibilityText = "cursor_animation_content-module--eligibilityText--520b1";
export var eligibilityTitle = "cursor_animation_content-module--eligibilityTitle--448c7";
export var footnote = "cursor_animation_content-module--footnote--d4906";
export var footnoteLink = "cursor_animation_content-module--footnoteLink--472d6";
export var imageContainer = "cursor_animation_content-module--imageContainer--67c37";
export var slider = "cursor_animation_content-module--slider--dad3a";
export var sliderContainer = "cursor_animation_content-module--sliderContainer--4d76c";
export var subtitle = "cursor_animation_content-module--subtitle--2270d";
export var title = "cursor_animation_content-module--title--0727e";