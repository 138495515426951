import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import OtherHero from '../components/other_hero/other_hero'
import Explanation from '../components/explanation/explanation'
import CursorAnimationContent from '../components/cursor_animation_content/cursor_animation_content'
import Testimonial from '../components/testimonial/testimonial'
import FAQContent from '../components/faq_content/faq_content'
import { assetNames } from '../utilities.js/constants'

import './base.css'
import Layout from '../components/website_layout/layout'
// Prevent Gatsby SSR build errors
const isBrowser = typeof window !== 'undefined'

const WageAdvance = (props) => {
  const assets = assetNames.reduce((res, assetName) => {
    res[assetName] = props.data[assetName]?.gatsbyImageData
    return res
  }, {})

  useEffect(() => {
    if (isBrowser) {
      // Track seeing this page
      window.analytics.track('website_page_viewed', {
        page: 'wage_advance',
      })
    }
  }, [])

  const siteMetadata = props.data.contentfulSiteMetadata
  // Override for page meta and title
  siteMetadata.title = props.data.contentfulWageAdvancePageContent.title
  siteMetadata.description =
    props.data.contentfulWageAdvancePageContent.description

  return (
    <>
      <Layout
        data={props.data.contentfulWageAdvancePageContent}
        seo={siteMetadata}
        assets={assets}
        showScrollToTop={true}
      >
        <OtherHero
          data={props.data.contentfulWageAdvancePageContent.mainContent}
          assets={assets}
          showAppLinks={true}
        />
        <Explanation
          data={props.data.contentfulWageAdvancePageContent.productListContent}
        />
        <FAQContent
          data={props.data?.contentfulWageAdvancePageContent.faqContent}
        />
        <CursorAnimationContent
          data={
            props.data.contentfulWageAdvancePageContent.cursorAnimationContent
          }
        />
        {/* <div className="md:-mb-32">
            <RecentBlogPosts />
          </div> */}
        <Testimonial
          data={props.data.contentfulWageAdvancePageContent.testimonialContent}
        />
      </Layout>
    </>
  )
}

export default WageAdvance
export const pageQuery = graphql`
  query WageAdvQuery {
    contentfulWageAdvancePageContent(
      contentful_id: { eq: "6msPFa337Jh4VoxIaP68Sk" }
    ) {
      title
      description
      topNavigationBar {
        navPaths {
          ... on ContentfulDropDown {
            internal {
              type
            }
            name
            linkEntries {
              label
              url
            }
          }
          ... on ContentfulPath {
            internal {
              type
            }
            url
            label
          }
        }
        logolarge {
          file {
            url
          }
        }
      }
      mainContent {
        rightImage {
          gatsbyImageData(width: 800)
          title
        }
        subtitle
        topBubbleText
        title
        fieldPlaceholder
        fieldButton {
          label
          url
        }
        formTitle
      }
      productListContent {
        id
        title
        paragraph
        imageStartsLeft
        productContentItem {
          subtitle
          title
          image {
            gatsbyImageData(width: 512)
            title
          }
          left
          paragraph {
            childMarkdownRemark {
              html
              rawMarkdownBody
              htmlAst
            }
          }
        }
      }
      faqContent {
        section {
          subtitle
          title
          image {
            gatsbyImageData(width: 80)
            title
          }
          question {
            question
            answer {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  gatsbyImageData
                  title
                }
              }
            }
          }
        }
      }
      testimonialContent {
        title
        item {
          rating
          title
          username
          paragraph {
            raw
          }
        }
      }
      cursorAnimationContent {
        bubbleMaxAmount
        bubbleMinAmount
        bubbleTitle
        conditions
        conditionSubtitleWithLink
        subtitle
        title
        eligibilityTitle
        cursorImage {
          gatsbyImageData(width: 384)
          title
        }
      }
      ctaSectionContent {
        successMessage
        errorMessage
        subtitle
        title
        placeholderTitle
        placeholderFieldName
        ctaTitle
        image {
          gatsbyImageData(width: 396)
          title
        }
      }
      footerContent {
        section1Title
        section2Title
        section3Title
        title
        section1Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 30)
            title
          }
        }
        section2Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 30)
            title
          }
        }

        section3Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 30)
            title
          }
        }
      }
    }
    googlePlayBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "q00MfpdmihX36ZSSzO2VS" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    appStoreBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "4JX8m4WjDpVydFskT6GEeu" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    googlePlayBadge: contentfulAsset(
      file: { fileName: { eq: "google-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    appStoreBadge: contentfulAsset(
      file: { fileName: { eq: "app-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    browserBadge: contentfulAsset(
      file: { fileName: { eq: "browser-badge.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    contentfulSiteMetadata(contentful_id: { eq: "4GzU8oQlPc2YDh3dwyPU1N" }) {
      description
      imageUrl
      siteName
      title
      twitterHandle
      siteUrl
    }
  }
`
