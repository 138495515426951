import React from 'react'
import Section from '../ui/section'
import Container from '../ui/container'
import Typography from '../ui/typography'
import checkmark from '../features/checkmark.svg'
import * as styles from './cursor_animation_content.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const { Paragraph } = Typography

export default (props) => (
  <Section className="bg-white">
    <Container className="flex-col flex items-center">
      <h2 className={styles.title}>{props.data.subtitle}</h2>
      <Paragraph className={styles.subtitle} responsive={true}>
        {props.data.eligibilityTitle}
      </Paragraph>
      <div className={styles.container}>
        <Paragraph className={styles.eligibilityTitle} responsive={true}>
          {props.data.title}
        </Paragraph>
        <div className={styles.imageContainer}>
          <GatsbyImage
            alt={props.data.cursorImage.title}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            image={props.data.cursorImage.gatsbyImageData}
          />
        </div>
        <div className={styles.eligibilityListContainer}>
          <ul className={`flex flex-col md:justify-between`}>
            {props.data.conditions.map((f, idx) => (
              <li key={idx} className={styles.eligibilityItem}>
                {/* TODO: Use image coming in from contentful */}
                <img
                  alt="checkmark"
                  src={checkmark}
                  width={20}
                  height={20}
                  // {/* Use these when we've changed the image + use gatsy-image Img instead */}
                  // fluid={checkmark}
                  // imgStyle={{
                  //   objectFit: 'contain',
                  //   objectPosition: 'right',
                  // }}
                  className={styles.eligibilityIcon}
                />
                <Paragraph className={styles.eligibilityText}>{f}</Paragraph>
              </li>
            ))}
          </ul>
          <Paragraph className={styles.footnote}>
            {props.data.conditionSubtitleWithLink.split(' ').map((part, idx) =>
              part == 'here.' || part == 'here' ? (
                <a key={idx} className={styles.footnoteLink} href={'/faq/'}>
                  {part}{' '}
                </a>
              ) : (
                <React.Fragment key={idx}>{part + ' '}</React.Fragment>
              )
            )}
          </Paragraph>
        </div>
      </div>
    </Container>
  </Section>
)
